import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import './App.css';
import Forbidden from './pages/Forbidden/Forbidden.component';
import NotFound from './pages/NotFound/NotFound.component';
import UserAccountList from './pages/UserAccount/UserAccountList.component';
import UserAccountDetail from './pages/UserAccount/UserAccountDetail.component';
import UserRoleList from './pages/Roles/UserRoleList.component';
import UserRoleDetail from './pages/Roles/UserRoleDetail.component';
import Home from './pages/Home/Home.component';
import PrivateRoute from './components/authorization/PrivateRoute.container';
import CaseList from './pages/Cases/CaseList.component';
import CaseDetail from './pages/Cases/CaseDetails.component';
import ValidationList from './pages/Validations/ValidationsList.component';
import ValidationDetail from './pages/Validations/ValidationDetail.component';
import AccountList from './pages/Accounts/AccountsList.component';
import AccountDetails from './pages/Accounts/AccountDetails.component';
import AssessmentList from './pages/Assessments/AssessmentList.component';
import AssessmentDetail from './pages/Assessments/AssessmentDetail.component';
import InsightsList from './pages/Insights/InsightsList.component';
import EmailTemplateList from './pages/EmailAdministration/EmailTemplateList.component';
import AssessmentTypeList from './pages/AssessmentTypes/AssessmentTypeList.component';
import { LicenseInfo } from '@mui/x-license-pro';
import EmailTemplateDetail from './pages/EmailAdministration/EmailTemplateDetail.component';
import ImportList from './pages/Imports/ImportList.component';
import ReleaseNotesList from './pages/ReleaseNotes/ReleaseNotesList.component';
import ReleaseNoteDetails from './pages/ReleaseNotes/ReleaseNoteDetails.component';
import DocumentsList from './pages/Documents/DocumentsList.component';
import DocumentDetail from './pages/Documents/DocumentDetails.component';
import AssessmentImportList from './pages/Imports/AssessmentImportList.component';
import { addLicense } from '@amcharts/amcharts5';
import InsightDetail from './pages/Insights/InsightsDetail.component';
import AARTemplateBuilder from './pages/AAR/AARTemplateBuilder.component';
import AccountImportList from './pages/Imports/AccountImportList.component';

function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/' element={<Home/>}/>,
        <Route path='insights' element={<InsightsList/>}/>,
        <Route path='insights/:dashboard' element={<InsightDetail/>}/>,
        <Route path="cases" element={ <PrivateRoute accessToken='Case_List_View'><CaseList/></PrivateRoute>}/>,
        <Route path="cases/:caseID" element={ <PrivateRoute accessToken='Case_Detail_View'><CaseDetail/></PrivateRoute>}/>,
        <Route path="accounts" element={ <PrivateRoute accessToken='Account_List_View'><AccountList/></PrivateRoute>}/>,
        <Route path="accounts/:accountID" element={ <PrivateRoute accessToken='Account_Detail_View'><AccountDetails/></PrivateRoute>}/>,
        <Route path="documents" element={ <PrivateRoute accessToken='Document_List_View'><DocumentsList/></PrivateRoute>}/>,
        <Route path="documents/:documentID" element={ <PrivateRoute accessToken='Document_Detail_View'><DocumentDetail/></PrivateRoute>}/>,
        <Route path="emailtemplates" element={ <PrivateRoute accessToken='EmailTemplate_List_View'><EmailTemplateList/></PrivateRoute>}/>,
        <Route path="emailtemplates/:emailTemplateID" element={ <PrivateRoute accessToken='EmailTemplate_Detail_View' ><EmailTemplateDetail/></PrivateRoute>}/>,
        <Route path="assessments" element={<PrivateRoute accessToken='Survey_List_View'><AssessmentList/></PrivateRoute>} />,
        <Route path="assessments/:assessmentID" element={<PrivateRoute accessToken='Survey_Detail_View'><AssessmentDetail/></PrivateRoute>}/>,
        <Route path="assessmentTypes" element={<PrivateRoute accessToken='SurveyType_List_View'><AssessmentTypeList/></PrivateRoute>}/>,
        <Route path='releaseNotes' element={ <PrivateRoute accessToken='ReleaseNote_List_View'><ReleaseNotesList/></PrivateRoute> }/>,
        <Route path="releaseNotes/:releaseNoteID" element={ <PrivateRoute accessToken='ReleaseNote_Detail_View'><ReleaseNoteDetails/></PrivateRoute> } />,
        <Route path='users' element={ <PrivateRoute accessToken='UserAccount_List_View'><UserAccountList/></PrivateRoute>}/>,
        <Route path="users/:userID" element={ <PrivateRoute accessToken='UserAccount_Detail_View'><UserAccountDetail/></PrivateRoute>} />,
        <Route path="userprofile" element={ <PrivateRoute><UserAccountDetail/></PrivateRoute>} />
        <Route path="userRoles" element={ <PrivateRoute accessToken='UserRole_List_View'><UserRoleList/></PrivateRoute>}/>,
        <Route path="userRoles/:userRoleID" element={ <PrivateRoute accessToken='UserRole_Detail_View'><UserRoleDetail/></PrivateRoute>}/>,
        <Route path="validations" element={ <PrivateRoute accessToken='Validation_List_View'><ValidationList/></PrivateRoute>}/>,
        <Route path="validations/:validationID" element={ <PrivateRoute accessToken='Validation_Detail_View'><ValidationDetail/></PrivateRoute>}/>,
        <Route path="imports/" element={ <PrivateRoute accessToken='Import_List_View'><ImportList/></PrivateRoute>}/>,
        <Route path="imports/accountImports/:importID" element={ <PrivateRoute accessToken='Import_Detail_View'><AccountImportList/></PrivateRoute>}/>,
        <Route path="imports/assessmentImports/:importID" element={ <PrivateRoute accessToken='Import_Detail_View'><AssessmentImportList/></PrivateRoute>}/>,
        <Route path="aar/:modelID/:uniqueID" element={<PrivateRoute accessToken='AssessmentReports_Detail_View'><AARTemplateBuilder/></PrivateRoute>}/>
        <Route path='forbidden' element={<Forbidden />} />,
        <Route path='*' element={<NotFound />} />,
      </>
    )
  );
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}
addLicense(process.env.REACT_APP_AMCHARTS5_LICENSE as string); //AmCharts5
addLicense(process.env.REACT_APP_AMCHARTS5_MAP_LICENSE as string); //AmCharts5-Map
LicenseInfo.setLicenseKey("0113af6aa1d860740eaacc441f27d1eaTz05MTAyMSxFPTE3NDc5MjYwMjgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");
console.log("%c\n\n██╗    ██╗ ██████╗ ███╗   ███╗  ██████╗  ██████╗\n██║    ██║   ██╔═╝ ████╗ ████║ ██╔════╝ ██╔════╝\n█████████║   ██║   ██╔████╔██║ ███████╗ ███████╗\n██╔════██║   ██║   ██║╚██╔╝██║ ╚════██║ ╚════██║\n██║    ██║ ██████╗ ██║ ╚═╝ ██║ ██████╔╝ ██████╔╝\n╚═╝    ╚═╝ ╚═════╝ ╚═╝     ╚═╝ ╚═════╝  ╚═════╝ \n\n\n", "font-family:monospace;color:#1976d2;font-size:12px;");
export default App;
